(function (angular, _, undefined) {
    "use strict";
    var blog = angular.module("mikesBlog");

    blog.filter("cardFilter", function () {
        return function (cardList, searchList) {
            var results = [],
                lowerCase = function (arr) { return _.map(arr, function (el) {return el.toLowerCase();});};

            if (searchList && searchList.length > 0) {
                _.each(cardList, function (card) {
                    var matches = _.intersection(lowerCase(card.tags), lowerCase(searchList)).length;
                    if (matches > 0) {
                        results.push({
                            card: card,
                            matches: matches
                        });
                    }
                });

                results.sort(function (a, b) {
                    if (a.matches === b.matches) {
                        return b.card.created.valueOf() - a.card.created.valueOf();
                    }
                    return b.matches - a.matches;
                });

            } else {
                return cardList;
            }

            if (results.length === 0) {
                return [];
            } else {
                return _.map(results, "card");
            }
        };
    });

})(angular, _);
